import React from 'react';
import { Radio } from 'lucide-react';

const AnimatedHeader = () => {
  return (
    <div className="relative w-full h-32 bg-hover-cream overflow-hidden">
      <div className="absolute inset-0 flex items-center">
        <div className="flex whitespace-nowrap">
          <div className="flex items-center gap-4 px-8">
            {/* First broadcasting tower */}
            <div className="relative">
              <Radio className="w-8 h-8 text-hover-espresso animate-pulse" />
              <div className="absolute -inset-2 border-2 border-hover-coffee/40 rounded-full animate-ping" />
              <div className="absolute -inset-4 border-2 border-hover-coffee/30 rounded-full animate-ping [animation-delay:150ms]" />
              <div className="absolute -inset-6 border-2 border-hover-coffee/20 rounded-full animate-ping [animation-delay:300ms]" />
            </div>
            
            <svg className="h-32 w-[1200px]" viewBox="0 0 1200 100">
              <path
                d="M0,50 C100,50 200,30 300,50 C400,70 500,30 600,50 C700,70 800,30 900,50 C1000,70 1100,30 1200,50"
                fill="none"
                stroke="rgba(139, 115, 85, 0.2)"
                strokeWidth="1"
                strokeLinecap="round"
              />
            </svg>

            {/* Second broadcasting tower */}
            <div className="relative">
              <Radio className="w-8 h-8 text-hover-espresso animate-pulse [animation-delay:200ms]" />
              <div className="absolute -inset-2 border-2 border-hover-coffee/40 rounded-full animate-ping [animation-delay:200ms]" />
              <div className="absolute -inset-4 border-2 border-hover-coffee/30 rounded-full animate-ping [animation-delay:350ms]" />
              <div className="absolute -inset-6 border-2 border-hover-coffee/20 rounded-full animate-ping [animation-delay:500ms]" />
            </div>

            <svg className="h-32 w-[1200px]" viewBox="0 0 1200 100">
              <path
                d="M0,50 C100,50 200,30 300,50 C400,70 500,30 600,50 C700,70 800,30 900,50 C1000,70 1100,30 1200,50"
                fill="none"
                stroke="rgba(139, 115, 85, 0.2)"
                strokeWidth="1"
                strokeLinecap="round"
              />
            </svg>

            {/* Third broadcasting tower */}
            <div className="relative">
              <Radio className="w-8 h-8 text-hover-espresso animate-pulse [animation-delay:400ms]" />
              <div className="absolute -inset-2 border-2 border-hover-coffee/40 rounded-full animate-ping [animation-delay:400ms]" />
              <div className="absolute -inset-4 border-2 border-hover-coffee/30 rounded-full animate-ping [animation-delay:550ms]" />
              <div className="absolute -inset-6 border-2 border-hover-coffee/20 rounded-full animate-ping [animation-delay:700ms]" />
            </div>
          </div>
        </div>
      </div>
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-hover-cream" />
    </div>
  );
};

export default AnimatedHeader;