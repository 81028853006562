import { useEffect } from "react";

const EventRegistration = () => {
  useEffect(() => {
    window.location.href = "https://pages.razorpay.com/pl_PVByIaFqi0mVsT/view";
  }, []);

  return (
    <div className="min-h-screen bg-hover-cream py-12 px-4 sm:px-6 lg:px-8 animate-fade-in">
      <div className="max-w-2xl mx-auto text-center">
        <h1 className="text-4xl font-bold text-hover-espresso">
          Redirecting to payment...
        </h1>
        <p className="mt-4 text-lg text-hover-coffee">
          Please wait while we redirect you to our secure payment page.
        </p>
      </div>
    </div>
  );
};

export default EventRegistration;