import { useEffect } from "react";

const Contact = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="min-h-screen bg-hover-cream">
      <div className="h-screen pt-16">
        <iframe 
          data-tally-src="https://tally.so/r/wgW0Ld" 
          width="100%" 
          height="100%" 
          frameBorder="0" 
          title="Contact form"
          className="border-0 w-full h-full"
          style={{ minHeight: '100vh' }}
        />
      </div>
    </div>
  );
};

export default Contact;