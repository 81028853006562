import AnimatedHeader from "@/components/AnimatedHeader";
import Hero from "@/components/sections/Hero";
import Events from "@/components/sections/Events";
import Partners from "@/components/sections/Partners";
import AudioPlayer from "@/components/AudioPlayer";

const Index = () => {
  return (
    <div className="min-h-screen bg-hover-cream pt-24">
      <AnimatedHeader />
      <main className="container max-w-4xl mx-auto px-4 pb-32">
        <Hero />
        <Events />
        <Partners />
      </main>
      <AudioPlayer />
    </div>
  );
};

export default Index;