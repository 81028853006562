import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useGoogleAnalytics } from "@/hooks/useGoogleAnalytics";
import Navigation from "@/components/Navigation";
import Index from "./pages/Index";
import LiveEvents from "./pages/LiveEvents";
import PartnerWithUs from "./pages/PartnerWithUs";
import Contact from "./pages/Contact";
import EventRegistration from "./pages/EventRegistration";
import Dashboard from "./pages/Dashboard";

const queryClient = new QueryClient();

const AppRoutes = () => {
  useGoogleAnalytics();
  
  return (
    <>
      <Navigation />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/live-events" element={<LiveEvents />} />
        <Route path="/partner" element={<PartnerWithUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/register" element={<EventRegistration />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </>
  );
};

const App = () => (
  <QueryClientProvider client={queryClient}>
    <TooltipProvider>
      <Toaster />
      <Sonner />
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </TooltipProvider>
  </QueryClientProvider>
);

export default App;