import { Link, useLocation } from "react-router-dom";
import { Calendar, Users, Mail, Menu, Home } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "@/components/ui/sheet";

const Navigation = () => {
  const location = useLocation();
  
  const links = [
    { to: "/", label: "Home", icon: Home },
    { to: "/live-events", label: "Live Events", icon: Calendar },
    { to: "/partner", label: "Partner with Us", icon: Users },
    { to: "/contact", label: "Contact Us", icon: Mail },
  ];

  const NavLinks = () => (
    <>
      {links.map(({ to, label, icon: Icon }) => (
        <Link key={to} to={to}>
          <Button
            variant="ghost"
            className={`flex items-center gap-2 ${
              location.pathname === to ? "bg-hover-coffee/10 text-hover-coffee" : ""
            }`}
          >
            <Icon className="h-4 w-4" />
            {label}
          </Button>
        </Link>
      ))}
    </>
  );

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-white/80 backdrop-blur-md border-b border-hover-coffee/10">
      <div className="container max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <Link to="/" className="flex items-center">
            <span className="text-2xl font-bold text-hover-espresso" style={{ fontFamily: 'Futura, sans-serif' }}>
              hb.
            </span>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center gap-2">
            <NavLinks />
          </div>

          {/* Mobile Navigation */}
          <Sheet>
            <SheetTrigger asChild className="md:hidden">
              <Button variant="ghost" size="icon">
                <Menu className="h-6 w-6" />
              </Button>
            </SheetTrigger>
            <SheetContent>
              <div className="flex flex-col gap-4 mt-8">
                <NavLinks />
              </div>
            </SheetContent>
          </Sheet>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;