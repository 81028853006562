import { MapPin, Calendar } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";

const events = [
  {
    id: 1,
    title: "The Start-up Brewhouse",
    image: "https://images.unsplash.com/photo-1517048676732-d65bc937f952?auto=format&fit=crop&w=800&q=80",
    location: "Starbucks - Sahakar Nagar",
    date: "December 13, 2024",
    time: "7:00 PM IST",
    description: "Promising startups meet to network, pitch their ideas to VCs, venture builders, and anyone that can volunteer to help them scale."
  },
  {
    id: 2,
    title: "The Bean Jam",
    image: "https://images.unsplash.com/photo-1507838153414-b4b713384a76?auto=format&fit=crop&w=800&q=80",
    location: "Starbucks - Sahakar Nagar",
    date: "Will be updated shortly!",
    time: "",
    description: "Experience live music like never before, as talented musicians fuse diverse styles to create a one-of-a-kind soundscape that will keep you grooving all night."
  },
  {
    id: 3,
    title: "Flavours Unveiled",
    image: "https://images.unsplash.com/photo-1495474472287-4d71bcdd2085?auto=format&fit=crop&w=800&q=80",
    location: "Starbucks - Sahakar Nagar",
    date: "Will be updated shortly!",
    time: "",
    description: "Embark on a culinary adventure with world-class chefs and food experts. Engage with them live, learn their secrets, and savor an unforgettable experience crafted by the best in the industry."
  },
  {
    id: 4,
    title: "Open Mic Night",
    image: "https://images.unsplash.com/photo-1442512595331-e89e73853f31?auto=format&fit=crop&w=800&q=80",
    location: "Bean & Note, Los Angeles",
    date: "Will be updated shortly!",
    time: "",
    description: "Showcase your talent and shine at our monthly open mic night – the stage is yours!"
  }
];

const Events = () => {
  return (
    <section className="mt-24 space-y-8">
      <div className="text-center space-y-6 mb-16">
        <h2 className="text-4xl font-bold text-hover-espresso">
          Live Events
        </h2>
        <p className="text-hover-coffee text-lg max-w-2xl mx-auto">
          Join us for unforgettable evenings of music, coffee, start-up networking, and community
        </p>
      </div>

      <div className="mt-12 relative px-12">
        <Carousel
          opts={{
            align: "start",
            loop: true,
          }}
          className="w-full"
        >
          <CarouselContent className="-ml-2 md:-ml-4">
            {events.map((event) => (
              <CarouselItem key={event.id} className="pl-2 md:pl-4 md:basis-1/2 lg:basis-1/3">
                <div className="group h-[600px] flex flex-col bg-white rounded-2xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
                  <div className="aspect-[4/3] relative overflow-hidden">
                    <img
                      src={event.image}
                      alt={event.title}
                      className="object-cover w-full h-full transition-transform duration-300 group-hover:scale-110"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                  </div>
                  
                  <div className="flex flex-col flex-grow p-6">
                    <h3 className="text-xl font-semibold text-hover-espresso mb-4">
                      {event.title}
                    </h3>
                    
                    <div className="space-y-2 mb-4">
                      <div className="flex items-center gap-2 text-hover-coffee">
                        <MapPin className="w-4 h-4" />
                        <span className="text-sm">{event.location}</span>
                      </div>
                      <div className="flex items-center gap-2 text-hover-coffee">
                        <Calendar className="w-4 h-4" />
                        <span className="text-sm">{event.date} {event.time && `• ${event.time}`}</span>
                      </div>
                    </div>
                    
                    <p className="text-sm text-hover-coffee/80 flex-grow">
                      {event.description}
                    </p>
                    
                    {event.title === "The Start-up Brewhouse" ? (
                      <a 
                        href="https://pages.razorpay.com/pl_PVByIaFqi0mVsT/view" 
                        target="_blank" 
                        rel="noopener noreferrer"
                      >
                        <Button className="w-full mt-6 bg-hover-coffee hover:bg-hover-espresso transition-colors">
                          Register Now
                        </Button>
                      </a>
                    ) : (
                      <Button className="w-full mt-6 bg-hover-coffee hover:bg-hover-espresso transition-colors" disabled>
                        Coming Soon
                      </Button>
                    )}
                  </div>
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious className="-left-12" />
          <CarouselNext className="-right-12" />
        </Carousel>
      </div>
    </section>
  );
};

export default Events;