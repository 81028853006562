import Events from "@/components/sections/Events";

const LiveEvents = () => {
  return (
    <div className="min-h-screen bg-hover-cream pt-24">
      <main className="container max-w-4xl mx-auto px-4 pb-32">
        <Events />
      </main>
    </div>
  );
};

export default LiveEvents;