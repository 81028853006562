import Partners from "@/components/sections/Partners";

const PartnerWithUs = () => {
  return (
    <div className="min-h-screen bg-hover-cream pt-24">
      <main className="container max-w-4xl mx-auto px-4 pb-32">
        <Partners />
      </main>
    </div>
  );
};

export default PartnerWithUs;